.animationGrid {
  display: grid;
  max-width: 95%;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  margin-top: -5px;
}
.animationGrid button {
  margin: 14px !important;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* padding: 24px 15px; */
  padding:0;
  font-size: 19px;
  height:80px;
}
