.input[type="range"] {
  height: 28px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 60%;
  background: none !important;
  border: none !important;
  padding-top: 25px;
  padding-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.input[type="range"]:focus {
  outline: none;
}

.input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: linear-gradient(to right, #ffaa86, #85e2ff);
  border-radius: 1px;
  border: 0px solid #010101;
}

.input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #00001e;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -17px;
}

.input[type="range"]:focus::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #ffaa86, #85e2ff);
}


.inputBrightness[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #111, #fff);
}

.inputBrightness[type="range"]::-webkit-slider-thumb {
  background: #ffffff;
}

.inputBrightness[type="range"]:focus::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #111, #fff);
}