:root {
  --primary: #33b4da;
  --primary-hover: #59ddcd;
  --danger: #f03564;
  --lightdark: #454545;
  --dark: #191919;
  --darker: black;
}

/* TangleButton START */

.button {
  color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 12px 20px;
  border: none;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  cursor: pointer;
  outline: none !important;
}

.button.primary {
  background: var(--primary);
}
.button.primary:hover {
  background: var(--primary-hover);
}

.button.danger {
  background: var(--danger);
}

.buttonBasic {
  color: gray;
  font-size: 19px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s all;
}

.buttonBasic.active {
  color: white;
  font-size: 19px;
  font-weight: 700;
}

/* TangleButton END */

/* TangleContainer START */
.container {
  background: var(--dark);
  display: flex;
  flex-direction: column;
  padding: 13px;
  border-radius: 20px;
  max-width: 100%;
}
/* TangleContainer END */

/* TangleInput START */
.input {
  outline: none !important;
  border: none;
  max-width: 100%;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 15px;
  color: #fff !important;
  font-size: 16px;
  margin: 10px 0px;
  font-weight: 600;

  border: 2px solid transparent !important;
}

.input:focus {
  border: 2px solid #d7dae4 !important;
}
/* TangleInput END */
