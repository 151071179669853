.header {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 10px;
	min-width: 300px;
	background-color: black;
}

.logo {
	height: 40px;
}
