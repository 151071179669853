@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  font-family: 'Montserrat', sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background: black;
  color: white;
  max-width: 1920px;
  max-height: 100vh;
  overflow: hidden;
  /* max-height: 1200px; */
  /* max-width: 1920px; */
  /* max-width: 150vh; */
  margin: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Header_header__3mObx {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	-webkit-align-items: center;
	        align-items: center;
	padding: 10px;
	min-width: 300px;
	background-color: black;
}

.Header_logo__2ins2 {
	height: 40px;
}

:root {
  --primary: #33b4da;
  --primary-hover: #59ddcd;
  --danger: #f03564;
  --lightdark: #454545;
  --dark: #191919;
  --darker: black;
}

/* TangleButton START */

.TangleComponents_button__2TZTb {
  color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 12px 20px;
  border: none;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  cursor: pointer;
  outline: none !important;
}

.TangleComponents_button__2TZTb.TangleComponents_primary__kROLC {
  background: #33b4da;
  background: var(--primary);
}
.TangleComponents_button__2TZTb.TangleComponents_primary__kROLC:hover {
  background: #59ddcd;
  background: var(--primary-hover);
}

.TangleComponents_button__2TZTb.TangleComponents_danger__2QxLo {
  background: #f03564;
  background: var(--danger);
}

.TangleComponents_buttonBasic__1FWuF {
  color: gray;
  font-size: 19px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s all;
}

.TangleComponents_buttonBasic__1FWuF.TangleComponents_active__2hy9r {
  color: white;
  font-size: 19px;
  font-weight: 700;
}

/* TangleButton END */

/* TangleContainer START */
.TangleComponents_container__1-r4i {
  background: #191919;
  background: var(--dark);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 13px;
  border-radius: 20px;
  max-width: 100%;
}
/* TangleContainer END */

/* TangleInput START */
.TangleComponents_input__3mKCq {
  outline: none !important;
  border: none;
  max-width: 100%;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 15px;
  color: #fff !important;
  font-size: 16px;
  margin: 10px 0px;
  font-weight: 600;

  border: 2px solid transparent !important;
}

.TangleComponents_input__3mKCq:focus {
  border: 2px solid #d7dae4 !important;
}
/* TangleInput END */

.SansButton_SansButton__3V9D0 {
  /* padding: 50px 35px; */
  background: #333333;
  transition: 0.3s all;
  border-radius: 5px;
  font-size: 30px;
  border: none;
  color: white;
  outline: none;
}

.SansButton_SansButton__3V9D0:hover {
  background: #444444;
}

.SansButton_SansButton__3V9D0.SansButton_active__2PcTD {
  background: #ffe58a;
  color: black;
}

.SansController_input__2y0xy[type="range"] {
  height: 28px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 60%;
  background: none !important;
  border: none !important;
  padding-top: 25px;
  padding-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.SansController_input__2y0xy[type="range"]:focus {
  outline: none;
}

.SansController_input__2y0xy[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: linear-gradient(to right, #ffaa86, #85e2ff);
  border-radius: 1px;
  border: 0px solid #010101;
}

.SansController_input__2y0xy[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #00001e;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -17px;
}

.SansController_input__2y0xy[type="range"]:focus::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #ffaa86, #85e2ff);
}


.SansController_inputBrightness__3Jf39[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #111, #fff);
}

.SansController_inputBrightness__3Jf39[type="range"]::-webkit-slider-thumb {
  background: #ffffff;
}

.SansController_inputBrightness__3Jf39[type="range"]:focus::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #111, #fff);
}
.Animation_animationGrid__uZ7vt {
  display: grid;
  max-width: 95%;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  margin-top: -5px;
}
.Animation_animationGrid__uZ7vt button {
  margin: 14px !important;
  width: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  /* padding: 24px 15px; */
  padding:0;
  font-size: 19px;
  height:80px;
}

.Interactive_music__3RATO {
    -webkit-animation: Interactive_breathe__1_rEm 2s cubic-bezier(.27,.5,.81,.68) 1s infinite;
            animation: Interactive_breathe__1_rEm 2s cubic-bezier(.27,.5,.81,.68) 1s infinite;
}

@-webkit-keyframes Interactive_breathe__1_rEm {
    0%   { -webkit-transform: scale(1); transform: scale(1); }
    50% { -webkit-transform: scale(1.05); transform: scale(1.05);}
    100% { -webkit-transform: scale(1); transform: scale(1);}
  }

@keyframes Interactive_breathe__1_rEm {
    0%   { -webkit-transform: scale(1); transform: scale(1); }
    50% { -webkit-transform: scale(1.05); transform: scale(1.05);}
    100% { -webkit-transform: scale(1); transform: scale(1);}
  }
