.App {
  text-align: center;
  background: black;
  color: white;
  max-width: 1920px;
  max-height: 100vh;
  overflow: hidden;
  /* max-height: 1200px; */
  /* max-width: 1920px; */
  /* max-width: 150vh; */
  margin: auto;
  user-select: none;
}
