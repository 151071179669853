.SansButton {
  /* padding: 50px 35px; */
  background: #333333;
  transition: 0.3s all;
  border-radius: 5px;
  font-size: 30px;
  border: none;
  color: white;
  outline: none;
}

.SansButton:hover {
  background: #444444;
}

.SansButton.active {
  background: #ffe58a;
  color: black;
}
